$Grey: #D7D7D9;
$DarkBlue: #000000;
$White: #F2F2F2;
$Blue: #273859;
$Beige: #D9A296;

@media only screen and (min-width: 1081px), (orientation: landscape) {

  .Rectang {
    width: 42rem;
    text-align: left;
    display: inline-block;
    height: 7.5rem !important;
    border: none !important;
    background-color: rgba($White, 0.9) !important;
    color: $DarkBlue;
    margin-top: 1rem;
    overflow-wrap: break-word;
  }

  .widthCheck{
  }

  .widthCheckMobile{
    display:none;
  }

}

@media only screen and (max-width: 1080px), (orientation: portrait)  {
  .Rectang {
    width: 21.3rem;
    height: 7.5rem !important;
    text-align: left;
    display: inline-block;
    border: none !important;
    background-color: rgba($White, 0.9) !important;
    color: $DarkBlue;
    margin-top: 1rem;
    overflow-wrap: break-word;
    overflow: hidden;
  }

  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
  a:active {
    text-decoration: none;
  }

  .KRcardText{
    margin-left: 3.1rem;
    margin-top: -3.7rem;
    width: 14rem;
  }

  .widthCheck{
    display:none;
  }

  .widthCheckMobile{
  }


}
