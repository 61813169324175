$Grey: #D7D7D9;
$DarkBlue: #000000;
$White: #F2F2F2;
$Blue: #273859;
$Beige: #D9A296;


p.AccoTitle:after {
  content: "\00a0";
}



@media only screen and (min-width: 1081px), (orientation: landscape) {

  .cv {
    background-color: rgba($Grey, 1);
    width: auto;
    left: 37vw;
    margin-top: -3.1vh;
    margin-left: 37vw !important;
    align-items: center !important;
    place-content: space-evenly;
    padding-left: 0;
    height: auto;
    min-height: 100vh;

    z-index: 90;
    overflow: visible;
  }

  .cv h2 {
    margin-top: 2.25rem;
    height: 2rem;
    color: $DarkBlue;
    text-align: left;
    margin-left: 2.6rem;
    position: absolute;
  }


  .AccoDiv {
    padding-top: 5.2rem !important;
    margin-left: 2.65rem !important;
    align-self: center;
    left: 40vw !important;
    text-align: left !important;
  }

  .AccoDiv2 {
    padding-top: 0 !important;
    margin-top: 0 !important;
    margin-left: 2.65rem !important;
    position: sticky;
    align-self: center;
    text-align: left !important;
  }

  .AccoDiv3 {
    padding-top: 0 !important;
    margin-left: 2.65rem !important;
    margin-top: 0 !important;
    position: sticky;
    align-self: center;
    left: 40vw !important;
    text-align: left !important;
  }

  .AccoDiv2BG {
    min-height: 14rem;
    background-color: rgba($Grey, 1) !important;
    height: auto;
    margin-bottom: -3.3rem;
  }

  .AccoDiv3BG {;
    background-color: rgba($Grey, 1) !important;
    min-height: 8rem !important;
    height: auto;
    margin-top: 3rem;
    padding-bottom: 3rem;
  }

  .AccoItem {
    width: 55vw;
    background-color: rgba($White, 0.9) !important;
  }

  .SmallAccoItem {
    background-color: rgba($White, 0.9) !important;
    width: 52.5vw !important;

  }

  p.AccoTitle {
    font-weight: bolder;
    font-size: large;
  }

  p.SmallAccoTitle {
    display: block;
    font-size: 1.05em;
    word-wrap: break-word;
    max-width: 35vw;

    font-weight: bold;

  }


  p.AccoTown {
    white-space: pre;
    font-size: large;

  }

  .AccoDate {
    left: 38vw;
    position: absolute;
  }

    .infoText {
    margin-top: -1.5vh !important;
    margin-bottom: 2vh !important;
    font-size: small !important;
    font-style: italic;
  }
  .SmallAccoDate {
    position: absolute;
    left: 37vw;
  }

  p.BodyText {
    margin-top: -1vh;
  }
  p.Prokura {
    font-weight: bold;

  }
  .ProkuraList ul {
    list-style-type: square;
  }

  p.Erfolge {
    font-weight: bold;
  }

  .ErfolgeList ul {
    list-style-type: square;
  }

  .Jahre {
    font-size: 1.5em !important;
    margin-bottom: -0.1vh;
    margin-left: 3.05vw;
    padding-bottom: 1vh;
  }

  #Jahr1985 {
    font-size: 1.5em !important;
    margin-bottom: -0.05rem;
    text-align: left;
    margin-left: 2.8rem;
    padding-bottom: 0.5rem;
    margin-top: 5rem;
    padding-top: 0.2rem;
    color: $DarkBlue;

  }

  #Jahr1980 {
    color: $DarkBlue;
    font-size: 1.5em !important;
    margin-bottom: -0.05rem;
    text-align: left;
    margin-left: 2.8rem;    padding-bottom: 0.5rem;
    padding-top: 5rem;
    margin-top: 0;
  }

  #AusHeading {
    margin-top: -3rem;
    margin-bottom: 2rem !important;
    margin-left: 2.6rem;

  }

  #StuHeading{
    margin-top: -1.5rem;
    padding-top: 3.5rem;
  }
}



@media only screen and (max-width: 1080px), (orientation: portrait)  {
  .cv {
    background-color: rgba($Grey, 1);
    width: auto;
    left: 0;
    margin-left: 0 !important;
    align-items: center !important;
    place-content: space-evenly;
    padding-left: 0;
    height: auto;
    min-height: 100vh;
    z-index: 90;
    overflow: visible;
  }

  .cv h2 {
    margin-top: 4vh;
    color: $DarkBlue;
    text-align: left;
    left: 5vw;
    position: absolute;
  }


  .AccoDiv {
    padding-top: 10vh !important;
    margin-left: 5vw !important;
    align-self: center;
    left: 0 !important;
    text-align: left !important;
  }

  .AccoDiv2 {
    padding-top: 0 !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    position: sticky;
    align-self: center;
    text-align: left !important;
  }

  .AccoDiv3 {
    padding-top: 0 !important;
    padding-bottom: 2rem;
    margin-left: 0 !important;
    margin-top: 1.6em !important;
    position: sticky;
    align-self: center;
    text-align: left !important;
  }

  .AccoDiv2BG {
    min-height: 20vh;
    margin-left: 5vw;
    background-color: rgba($Grey, 1) !important;
    height: auto;
    margin-bottom: -5vh;
  }

  .AccoDiv3BG {
    background-color: rgba($Grey, 1) !important;
    margin-left: 5vw;
    margin-bottom: -5vh;
    min-height: 15vh !important;
    height: auto;
    margin-top: 4.5rem;
    padding-bottom: 6vh !important;
    text-align: left;
  }

  .AccoItem {
    width: 90vw;
    background-color: rgba($White, 0.9) !important;
  }

  .SmallAccoItem {
    background-color: rgba($White, 0.9) !important;
    width: 81.5vw !important;

  }

  p.AccoTitle {
    font-weight: bolder;
    font-size: medium;
  }

  p.SmallAccoTitle {
    display: block;
    font-size: 0.8em;
    word-wrap: break-word;
    max-width: 56vw;
    font-weight: bold;
    margin-left: -1.5vw;

  }



  p.AccoTown {
    white-space: pre;
    font-size: medium;

  }

  .AccoDate {
    left: 65vw;
    position: absolute;
    max-width: 12vw;
    word-wrap: break-word;
  }

  p.AccoDate{
    font-size: x-small;
  }

  .infoText {
    margin-top: -1.5vh !important;
    margin-bottom: 2vh !important;
    font-size: small !important;
    font-style: italic;
  }

  .SmallAccoDate {
    position: absolute;
    left: 60vw;
    max-width: 12vw;
    word-wrap: break-word;
  }

  p.SmallAccoDate{
    font-size: x-small;
  }

  p.BodyText {
    margin-top: -1vh;
  }
  p.Prokura {
    font-weight: bold;

  }
  .ProkuraList ul {
    list-style-type: square;
  }

  p.Erfolge {
    font-weight: bold;
  }

  .ErfolgeList ul {
    list-style-type: square;
  }



  #Jahr1985 {
    margin-left: -67vw;
    font-size: 1.2em !important;
    margin-bottom: -0.1vh;
    padding-bottom: 1vh;
    margin-top: 10vh;
    padding-top: 0;
    color: $DarkBlue;

  }

  #Jahr1980 {
    font-size: 1.2em !important;
    margin-bottom: -0.1vh;
    padding-bottom: 1vh;
    padding-top: 1.5vh;
    margin-top: 0;
  }

  #AusHeading {
    margin-top: -5vh;
    margin-bottom: 6vh !important;
    margin-left: 0.1vw;

  }
  #StuHeading{
    margin-top: -1.5rem;
    padding-top: 3.5rem;
  }



}

