$Grey: #D7D7D9;
$DarkBlue: #000000;
$White: #F2F2F2;
$Blue: #273859;
$Beige: #D9A296;

@media only screen and (min-width: 1081px), (orientation: landscape) {

  #kontaktBG {
    background-color: $Grey !important;
    width: auto;
    left: 37vw;
    margin-left: 37vw !important;
    align-items: center;
    align-content: center;
    place-content: space-evenly;
    padding-left: 0;
    height: 48.9rem;
    min-height: 60vh !important;
    z-index: 91;
    flex-grow: 1;
    overflow: visible;

  }

  #kontaktBG h2 {
    margin-top: 2.25rem;
    height: 2rem;
    color: $DarkBlue;
    text-align: left;
    margin-left: 2.6rem;
    position: absolute;
  }

  #formular {
    color: transparent;
    text-align: left;
    left: 39vw;
    position: absolute;
    margin-top: 5rem;
  }

  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
  a:active {
    text-decoration: none;
  }


  .icon {
    margin-left: -1rem;
    width: 3rem;
    height: 3rem;
  }

  .LogoDiv{
    background-image: url("../assets/icons/testIcon2.svg");
    background-repeat: no-repeat;
    width: 250px;
    height: 250px;
    position:absolute;
    top: 20rem;
    left: 12.8rem;
  }


}

@media only screen and (max-width: 1080px), (orientation: portrait) {


  #kontaktBG {
    background-color: $Grey !important;
    width: auto;
    align-items: center;
    place-content: space-evenly;
    margin-left: -1rem;
    padding-left: 0;
    min-height: 100vh;
    flex-grow: 1;
    z-index: 91;
    overflow: hidden;
  }
  .LogoDiv
  {
    background-image: url("../assets/icons/testIcon2.svg");
    background-repeat: no-repeat;
    width: 170px;
    height: 170px;
    position:absolute;
    top: 20rem;
    left: 5.9rem;
  }
  #kontaktBG h2 {
    margin-top: 2.25rem;
    height: 2rem;
    color: $DarkBlue;
    text-align: left;
    margin-left: 2.6rem;
    position: absolute;
  }

  #formular {
    left: 1rem;
    color: transparent;
    text-align: left;
    position: absolute;
    margin-top: 5rem;
  }

  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
  }
  a:active {
    text-decoration: none;
  }


  .icon {
    margin-left: -1rem;
    width: 3rem;
    height: 3rem;
  }

}



.LogoCol{
  align-content: center;
}








