$Grey: #D7D7D9;
$DarkBlue: #000000;
$White: #F2F2F2;
$Blue: #273859;
$Beige: #D9A29;

@media only screen and (min-width: 1081px), (orientation: landscape) {

  #InteressenBG {
    background-color: $White;
    width: auto;
    left: 37vw;
    margin-left: 37vw !important;
    align-items: center;
    place-content: space-evenly;
    padding-left: 0;
    min-height: 100vh;
    z-index: 90;
    flex-grow: 1;
  }

  #InteressenBG h2 {
    margin-top: 2rem;
    color: $DarkBlue;
    text-align: left;
    left: 40vw;
    position: absolute;
  }

  .FDiv{
    text-align: left;
  }

  .FDiv h3{
    font-size: 1.8rem;
  }

  .IDiv{
    text-align: left;
  }

  .IDiv h3{
    font-size: 1.8rem;
  }

  .ZParent{
    text-align: left;
  }

  .ZParent h3{
    font-size: 1.8rem;
  }

  .ZDiv {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 2.3rem !important;
    grid-row-gap: 0.2rem;
    width: 42rem;
  }


  .ZCard {
    text-align: left;
    width: 20rem;
    height: 7.5rem !important;
    border: none !important;
    background-color: rgba($Grey, 0.2) !important;
    margin-top: 1rem;
  }

  .ICard {
    width: 42rem;
    text-align: left;
    display: inline-block;
    height: 7.5rem !important;
    border: none !important;
    background-color: rgba($Grey, 0.2) !important;
    margin-top: 1rem;
  }

  .iCardyGrid {
    margin-top: -0.4rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0 !important;
    grid-row-gap: 0.4rem;
    width: 40rem;
  }
  .IDiv {
    margin-top: 1.5rem;
    margin-left: 2.5rem;
  }
}

@media only screen and (max-width: 1080px), (orientation: portrait) {

  #InteressenBG {
    text-align: left;
    background-color: $White;
    width: auto;
    align-items: center;
    place-content: space-evenly;
    margin-left: -1rem;
    padding-left: 0;
    min-height: 100vh;
    z-index: 90;
    flex-grow: 1;
  }

  #InteressenBG h2 {
    margin-top: 2rem;
    color: $DarkBlue;
    text-align: left;
    left: 1.39rem;
    position: absolute;
  }

  .FDiv h3 {

  }

  .ZParent h3 {
   width: 80vw;
    line-break: auto;

  }

  .IDiv h3 {
  }

  .ZDiv {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-column-gap: 2.3rem !important;
    grid-row-gap: 0.2rem;
    width: 21.3rem;
  }

  .ZCard {
    text-align: left;
    width: 21.3rem;
    height: 7.5rem !important;
    border: none !important;
    background-color: rgba($Grey, 0.2) !important;
    margin-top: 1rem;
  }

  .ICard {
    width: 21.3rem;
    text-align: left;
    display: inline-block;
    height: 7.5rem !important;
    border: none !important;
    background-color: rgba($Grey, 0.2) !important;
    margin-top: 1rem;
  }

  .iCardyGrid {
    margin-left: -0.2rem;
    margin-top: -0.3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-column-gap: 0 !important;
    grid-row-gap: 0.4rem;
    width: 21.3rem;
    padding-bottom: 2rem !important;
  }

  .IDiv {
    margin-top: 1.5rem;
    margin-left: 2.5rem;
    padding-bottom: 2rem;
  }

}




  .FDiv {
    margin-left: 2.5rem;
    padding-top: 5.2rem;
  }


  .FCard {
    width: 21.3rem;
    text-align: left;
    display: inline-block;
    height: 7.5rem !important;
    border: none !important;
    background-color: rgba($Grey, 0.2) !important;
    margin-top: 1rem;
    right: 0 !important;
  }

  .ZParent {
    margin-left: 2.5rem;
    margin-top: 1.4rem;
  }










  .miniICard {
    border: none !important;
    background-color: rgba($White, 0.7) !important;
    height: 3rem;
    text-align: left !important;
    width: 9rem;
  }

  .miniICard p {
    margin-top: -0.25rem;
  }






