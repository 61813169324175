$Grey: #D7D7D9;
$DarkBlue: #000000;
$White: #F2F2F2;
$Blue: #273859;
$Beige: #D9A296;

@media only screen and (max-width: 1080px), (orientation: portrait)  {
  .aboutMeBg {
    background: rgba(0, 0, 0, 0.5) url('../assets/imgs/reg7-480.jpg') no-repeat -60vw 8vh;
    -webkit-background-size: 240vw 100vmax; /* For WebKit*/
    -moz-background-size: 240vw 100vmax;    /* Mozilla*/
    -o-background-size: 240vw 100vmax;      /* Opera*/
    background-size: 240vw 100vmax;
    background-blend-mode: darken, luminosity;
    background-origin: padding-box;
    color: $White;
    left: 0;
    height: 100vh;
    width: 100vw;
    align-items: center;
    place-content: space-evenly;
    min-height: 100vh;
    z-index: 90;
    flex-grow: 1;

  }
  .text {
    text-align: left;
    left: 5vw;
    position: absolute;
    margin-top: 35vh;
    font-size: smaller;
  }
  .aboutMeBg p {
    padding-top: 3vh;

  }
  .text h1, h2, p {
    text-decoration: none;
    margin-bottom: -3vh;
  }

  .text p {
    margin-top: -2vh;
  }

  .text Button {
    margin-right: auto;
    margin-left: auto;
    width: 70vw;
  }

}

@media only screen and (min-width: 1081px), (orientation: landscape) {

  .watermark{
    background-image: url("../assets/icons/testIcon.svg");
    background-repeat: no-repeat;
    left: 15vw;
    top: -30vh;
    opacity: 20%;
    width: 90%;
    height: 90%;
    position:absolute;
    bottom:0;
  }

  .aboutMeBg {
    //background-image: url('../assets/imgs/HJ_PB_Screenshot.PNG');
    background-color: $Grey;
    width: auto;
    left: 37vw;
    margin-left: 37vw !important;
    align-items: center;
    place-content: space-evenly;
    padding-left: 0;
    min-height: 100vh !important;
    z-index: 91;
    flex-grow: 1;
    overflow: visible;

  }



  .aboutMeBg p {
    padding-top: 3vh;

  }

  .text {
    text-align: left;
    left: 40vw;
    position: absolute;
    min-height: 50rem !important;
    height: auto;
    overflow: visible;
    margin-top: 40vh;
  }

  .text h1, h2, p {
    text-decoration: none;
    margin-bottom: -3vh;
  }

  .text p {
    margin-top: -2vh;
    font-size: 1.2rem;
  }

  .text Button {
    margin-right: auto;
    margin-left: auto;
    width: 30vw;
  }
}
