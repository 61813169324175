$Grey: #D7D7D9;
$DarkBlue: #000000;
$White: #F2F2F2;
$Blue: #273859;
$Beige: #D9A296;


@media only screen and (max-width: 1080px), (orientation: portrait)  {
  .topNav{
    z-index: 101;
    background-color: $Blue !important;
    opacity: 0.99;
    max-height: 8.2vh;
    align-content: start;
  }

  .MobileBtn{
    left: -1.5rem !important;
  }

  .mobileTxt{
    padding-right: 5rem;
    padding-left: -1rem;
  }

  .lngIcon{
    width: 0.4rem;
    height: 0.4rem;
    top: 0.9rem;
    right: 1rem !important;
    position: fixed;
    position: -ms-device-fixed;

  }

  .navText{
    position: absolute;
    left: -2vw !important;
    color: $Beige !important;
    opacity: 2 !important;
    font-size: 0.7em !important;
    margin-top: 0 !important;
  }

  .NavLink{
    margin-right: -5vw;
  }
  .NavLink:hover{
    color: $Beige !important;
  }


  .NavBrand {
    visibility: hidden;
  }

}
@media only screen and (min-width: 1081px), (orientation: landscape) {

  .dropdown-item {
    width: max-content !important;
  }

  .dropdown-menu {
    justify-content: center !important;
    max-height: max-content;
    max-width: 3rem !important;
  }

  .dropdown-menu .dropdown-item.active {
    background-color: rgba($Blue, 0.2);
    color: $DarkBlue;
  }

  .dropdown-menu > a:hover {
    background-color: rgba($Blue, 0.05);
    color: $DarkBlue;
  }

  .dropdown-toggle::before, .dropdown-toggle::after{
    content: none !important;
  }

  .lngChange .dropdown-menu{
    width: 10rem!important;
    text-align: right;

  }

  .lngChange{
    text-align: right !important;
    position: fixed;
    right: -13.07rem !important;
    top: -0.8rem;
    border:none !important;
  }

  .lngChange-tab{
    margin-right: 4rem !important;
    padding-right: 4rem !important;
  }

  .lngIcon{
    width: 1.3rem;
    height: 1rem;
    top: 1.25rem;
    right: 2rem !important;
    position: fixed;
    position: -ms-device-fixed;

  }

  .topNav {
    z-index: 101;
    background-color: $Blue !important;
    opacity: 0.99;
    max-height: 3.8rem;
    min-height: 3.5rem;
  }

  .navText {
    position: fixed;
    position: -ms-device-fixed;
    left: 38vw !important;
    color: $Beige !important;
    opacity: 2 !important;
    font-size: larger !important;
    margin-top: 0 !important;
  }

  .LogoText{
    margin-left:0;
    font-size: 1.3rem;
  }

  .NavBrand{
    top: -0.7rem;
  }

  .NavBrand p{
    font-size: 2rem;
  }

  .NavLink:hover {
    color: $Beige !important;
  }


  .NavLogo {
    margin-top: 10vh;
  }

  .NavBrand {
    position: fixed;
    position: -ms-device-fixed;
    left: 2.5rem;
    font-size: medium !important;
    margin-top: 1.8vh !important;;
  }

  .NavBrand img {
    margin-top: -0.55rem;
  }
}


