@media only screen and (min-width: 1081px), (orientation: landscape) {

  .BigPicSettings {
    width: 37vw;
    left: 0;
    min-height: 100vh;
    overflow-x: hidden;
    position: fixed;
    bottom: 0;
    top: 6vh;
    z-index: 90;
    float: left;
  }

  .BigPicSettings img {
    bottom: 0;
    left: 0;
    object-position: 48% bottom;
    object-fit: cover;
    width: 100%;
    height: 100vh;
    position: absolute;
    opacity: 100%;
  }
}

@media only screen and (max-width: 1080px), (orientation: portrait)  {
  .BigPicSettings {
    display: none;
    width: 0;
    height: 0;
  }

  .BigPicSettings img {
    display: none;
    width: 0;
    height: 0;
  }
}
