$Grey: #D7D7D9;
$DarkBlue: #000000;
$White: #F2F2F2;
$Blue: #273859;
$Beige: #D9A296;

@media only screen and (max-width: 1080px), (orientation: portrait)  {
  .kompetenzenBG {
    background-color: rgba($Grey, 0.3);
    margin-top: 0;
    width: auto;
    height: 209vh;
    padding-bottom: 2rem;
    place-content: space-evenly;
    padding-left: 0;
    z-index: 90;
    flex-grow: 1;
    overflow: hidden !important;
  }

  #Title {
    margin-top: 6vh;
    color: $DarkBlue;
    text-align: left;
    left: 5vw;
    position: absolute;
  }

  .listing {
    margin-top: 15vh;
    color: $DarkBlue;
    text-align: left;
    left: 5vw;
    right: 5vw;
    position: absolute;


  }
  .listing Card {
  }

  .listing li {
    background-color: rgba($Grey, 0.2) !important;
    font-size: large;
    border-color: $DarkBlue;
    border-radius: 0.1vw;
  }

  .listing li ul li {
    font-size: medium;
    background-color: transparent !important;
  }

  .KCard {
    width: 20rem;
    display: inline-block;
    min-height: 20vh;
    border: none !important;
    background-color: rgba($Grey, 0.2) !important;
    margin-top: 2vh;
    font-size: smaller;
  }

  .cardBox {
    margin-right: 1.5vw;
    display: flex;
    flex-flow: wrap;
  }
}
@media only screen and (min-width: 1081px), (orientation: landscape) {



  .kompetenzenBG {
    background-color: rgba($Grey, 0.3);
    margin-top: -2vh;
    width: auto;
    left: 37vw;
    height: 59.6rem;
    margin-left: 37vw !important;
    place-content: space-evenly;
    padding-left: 0;
    z-index: 90;
    flex-grow: 1;
    overflow: visible !important;
  }

  #Title {
    margin-top: 2.3rem;
    height: 2rem;
    color: $DarkBlue;
    text-align: left;
    left: 40vw;
    position: absolute;
  }

  .listing {
    color: $DarkBlue;
    text-align: left;
    left: 40vw;
    position: absolute;
    margin-top: 5rem;
    right: 3rem;
  }


  .listing li {
    background-color: rgba($Grey, 0.2) !important;
    font-size: large;
    border-color: $DarkBlue;
    border-radius: 0.1vw;
  }

  .listing li ul li {
    font-size: medium;
    background-color: transparent !important;
  }

  .KCard {
    width: 20rem;
    display: inline-block;
    min-height: 20vh;
    height: 10rem;
    border: none !important;
    background-color: rgba($Grey, 0.2) !important;
  }

  .cardBox {
    height: 48rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 1rem;
    margin-right: 1.5vw;
    flex-flow: wrap;
  }
}
/*.cardText{
  top: 8vh;
  position: absolute;
}

 */


